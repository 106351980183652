<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <router-link to="/profile" class="back-profile">
        <img src="@/assets/img/arrow-round-back.svg" alt="back" /> Назад
      </router-link>
      <div class="wrapper-promocode">
        <h2>Смотреть позже</h2>
        <div class="wrapper-promocode-form">
          <media-views-block
            v-if="watchLaterVideos.length"
            :media="watchLaterVideos"
            :update-media="loadVideos"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MediaViewsBlock from "@/components/page/media/MediaViewsBlock";

export default {
  name: "WatchLater",
  components: { MediaViewsBlock },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapGetters(["watchLaterVideos"])
  },
  methods: {
    ...mapActions(["getWatchLaterVideos"]),
    loadVideos() {
      this.getWatchLaterVideos({
        page: this.page
      }).then(() => {
        this.page++;
      });
    }
  },
  created() {
    this.loadVideos();
  }
};
</script>

<style scoped></style>
